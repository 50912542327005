<template>
    <!-- <div class='card' data-bs-toggle="modal" data-bs-target="#card-modal" @click="callModal(cardIndex)"> -->
    <div class='card br-16 shadow' @click="openFullCard" tabindex="0">
      <!-- Card text -->
      <div class="card-body text-start">
        <markdown-it-vue-light class="md-body" :content="cardContent" :options="mdoptions"/>
      </div>
      <div class="card-footer d-flex justify-content-between">
        <small style="font-weight: 500; font-variant-caps: all-petite-caps;">{{ card.subject }}</small>
        <small><em :id="'user-activity-text-' + cardIndex"></em></small>
      </div>
    </div>
</template>

<script>
import MarkdownItVueLight from 'markdown-it-vue/dist/markdown-it-vue-light.umd.min.js'

export default {
  name: 'Card',
  props: {
    card: Object,
    cardIndex: Number
  },
  data() {
    return {
      bookmarked: false,
      proUser: true,
      // cardPalette: ["#003f5c","#444e86","#955196","#dd5182","#ff6e54","#ffa600"],
      // cardPalette: ["#eff1db", '#ffd4db', '#d3b5e5', '#bbe7fe'],
      cardPalette: ["#c0d8c0", "#9ad6ed", "#e6b8b8", "#a7cac7", "#cdb4d1", "#a8b5c5"],
      // cardPalette: ["#edf2fb", "#e2eafcff", "#d7e3fcff", "#c1d3feff"],
      mdoptions: {
        markdownIt: {
          linkify: true,
          breaks: true
        },
        linkAttributes: {
          attrs: {
            target: '_blank',
            rel: 'noopener nofollow'
          }
        },
      },
      cardContent: ''
    }
  },
  components: {
    MarkdownItVueLight
  },
  mounted() {
    this.setCardContent()
    // set bg colour
    var el = document.getElementById('card-' + this.cardIndex)
    el.style.backgroundColor = this.cardPalette[this.cardIndex%this.cardPalette.length] + '80'
  },
  methods: {
    setCardContent() {
      if (this.$store.state.lang === 'en') {
        var fullCardContent = this.card.en
        var partialContent = fullCardContent.substr(0,100) + '\n\n' + 'Read more...'
        this.cardContent = partialContent
      } else if (this.$store.state.lang === 'hi') {
        fullCardContent = this.card.hi
        partialContent = fullCardContent.substr(0,100) + '\n\n' + 'पूरा पढ़ें...'
        this.cardContent = partialContent
      }
    },
    callModal(index) {
      this.$emit("call", index)
    },
    openFullCard() {
      this.$store.commit('setCardIndex', this.cardIndex)
      // this.$router.push('/card')
      var cardlink = '/card-' + this.card.secret_id
      this.$router.push(cardlink)
    }
  }
}
</script>

<style scoped>
.card {
  height: 300px;
  cursor: pointer;
  max-width: 400px;
}
.card:focus {
  border: 2px solid gray;
}
.card-body {
  height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.md-body {
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-footer {
  text-align: left;
  margin-bottom: 0;
}
.meta {
  line-height: 1rem;
  font-weight: 450;
  font-variant-caps: all-petite-caps;
  font-size: 1.2em;
  color: rgb(69, 69, 69);
}
.btn-interaction {
  width: 60px
}
.md-body > p > img {
  display: none !important;
}
</style>