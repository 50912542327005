<template>
  <section class="container py-4"> 
    <h2>{{ currentUser.firstName }}{{$t('bookmarks-header')}}</h2>
    <h3>{{ currentUser.bookmarks.length }} {{$t('bookmarks-counter')}}</h3>
    <div class="spinner-border my-5" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <CardsList v-bind:cards="bookmarkCards"/>
  </section>
</template>

<script>
import CardsList from './CardsList.vue';
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {CardsList  },
  name: 'Bookmarks',
  metaInfo: {
    title: 'My Bookmarks',
    titleTemplate: '%s | Chachi',
  },
  data: function() {
    return {
    //     bookmarks: [{"subject": "SUBJECT", "en": "### Get ready!", 'hi':"### तैयार हो जाइए!"},
    // {"subject": "SUBJECT", "en": "### Almost there!", 'hi':"### बस हो गया समझो"}]
      // bookmarks: []
      bookmarkCards: []
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
      currentUser: state => state.currentUser,
      lang: state => state.lang,
      bookmarks: state => state.currentUser.bookmarks,
      // bookmarkCards: state => state.bookmarkCards
    })
  },
  mounted() {
    // only fetch bookmarks if they aren't already saved in store
    // or if there has been a modification (removal/addition)
    this.bookmarkCards = this.$store.state.bookmarkCards
    var totalBookmarks = this.bookmarks.length
    // if (this.currentUser.bookmarks.length > 0) {
    if (totalBookmarks === 0) {
      document.getElementsByClassName('spinner-border')[0].classList.add('d-none')
    } else if (this.bookmarkCards.length != totalBookmarks || this.bookmarkCards[totalBookmarks-1]['secret_id'] != this.bookmarks[totalBookmarks-1]) {
      this.fetchBookmarks()
    }
  },
  methods: {
    fetchBookmarks() {
        const token = this.$cookies.get('user-jwt')
        const config = { headers: { "Authorization": `Bearer ${token}`, "Access-Control-Expose-Headers": "Authorization" } };
        // don't delete
        // var api_link = "https://api.chachi.app/v2/users/fetch/bookmarks/" + encodeURI(this.lang) + "?user=" + this.currentUser.uid
        var api_link = "https://api.chachi.app/v2/bank/" + encodeURI(this.lang) + "/" + this.bookmarks.join('+')
        axios.get(api_link, config)
          .then( response => {
            // this.bookmarks = response.data
            this.bookmarkCards = response.data
            // save bookmarks in store for later use
            this.$store.dispatch('setBookmarkCards', this.bookmarkCards)
            document.getElementsByClassName('spinner-border')[0].classList.add('d-none')
          })
          .catch (err => {
            if (err.response.data.detail === "Invalid token or expired token.") {
              var newTokenSet = this.$store.dispatch('setUserJWT')
              if (newTokenSet) {this.fetchBookmarks()}
            }
          })
    },
    fetchAgain() {
      this.$store.dispatch("setCurrentUser")
    }
  }
};
</script>

<style scoped>
.card {
	border-radius: 8px;
  width: 24rem;
}
@media screen and (max-width: 768px) {
  .card {
    width: 18rem;
  }
}
a {
  text-decoration: none;
}
#delete-link {
  text-decoration: underline;
  color:#dc3545;
}
</style>