<template>
  <div class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <!-- Meta information -->
          <div class="">
            <a class="col meta" v-bind:href="'#'" id="card-subject"></a>&nbsp;
            <a class="col meta" v-bind:href="'/#/cache?id=' + 'link._id'" id="card-life-skill"></a>&nbsp;
            <a class="col meta" v-bind:href="'/#/cache?id=' + 'link._id'" id="card-topic"></a>&nbsp;
            <a class="col meta" v-bind:href="'#'" id="card-class-range"></a>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <!-- Main text -->
          <h4 id='activity-text' class='text-start'>
          </h4>

          <!-- Response after submission -->
          <h2 class='d-none' id="submission-response"></h2>
        </div>
        <div class="modal-footer justify-content-center">
          <!-- Bookmark -->
          <a v-if="!bookmarked" v-bind:href="'#'" class="btn btn-warning btn-sm my-2 me-2 btn-interaction" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16"><path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/></svg></a>
          <a v-if="bookmarked" v-bind:href="'#'" class="btn btn-warning btn-sm my-2 me-2 btn-interaction" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/></svg></a>
          <!-- Add notes -->
          <a v-if="proUser" v-bind:href="'/#/cache?id=' + 'link._id'" class="btn btn-success btn-sm my-2 mx-2 btn-interaction" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal" viewBox="0 0 16 16"><path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/><path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/></svg></a>
          <!-- Read comments -->
          <a v-bind:href="'#'" class="btn btn-light btn-sm my-2 mx-2 btn-interaction" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16"><path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/><path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/></svg></a>
          <!-- Share -->
          <a v-bind:href="'#'" class="btn btn-success btn-sm my-2 mx-2 btn-interaction" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16"><path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/></svg></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardmodal',
  props: {
  },
  data() {
    return {
      bookmarked: false,
      proUser: true,
      cardPalette: ["#003f5c","#444e86","#955196","#dd5182","#ff6e54","#ffa600"],
      // cardPalette: ["#eff1db", '#ffd4db', '#d3b5e5', '#bbe7fe']
    }
  },
  components: {
  },
  mounted() {
    // var el = document.getElementById('card-' + this.cardIndex)
    // el.children[1].style.backgroundColor = this.cardPalette[this.cardIndex%this.cardPalette.length] + '40'
  },
  methods: {
  }
}
</script>

<style scoped>
.meta {
  line-height: 1rem;
  font-weight: 450;
  font-variant-caps: all-petite-caps;
  font-size: 1.2em;
  color: rgb(69, 69, 69);
}
.btn-interaction {
  width: 50px
}
.modal-footer {
  padding: 0;
}
.modal-header {
  padding-top: 0;
  padding-bottom: 0;
}
</style>