<template>
    <div class='col-lg-8 mx-auto my-4'>
        <!-- Card view -->
        <div id='card-view' class='row row-cols-1 row-cols-md-2'>
            <div v-bind:key="card.secret_id" v-for="(card, index) in cards" class="col cards-div my-4">
                <Card v-bind:card="card" :cardIndex="index" :id="'card-' + index" @call="populateModal" />
            </div>
        </div>

    <!-- Full card modal -->
    <cardmodal id="card-modal"/>
    </div>
</template>

<script>
import Card from './Card'; 
import cardmodal from './Modal'

// eslint-disable-line no-unused-vars
export default {
    name: "CardsList",
    components: {
        Card,
        cardmodal
    },
    data() {
      return {
      };
    },
    props: ["cards"],
    methods: {
        populateModal(e) {
            e
            document.getElementById('activity-text').textContent = this.cards[e].en
            document.getElementById('card-subject').textContent = this.cards[e].subject
            document.getElementById('card-life-skill').textContent = this.cards[e].life_skill
            if (this.cards[e].topic === 'null') {
                document.getElementById('card-topic').classList.add('d-none')
            } else {
                document.getElementById('card-topic').classList.remove('d-none')
                document.getElementById('card-topic').textContent = this.cards[e].topic
            }
            if (this.cards[e].class_range === 'null') {
                document.getElementById('card-class-range').classList.add('d-none')
            } else {
                document.getElementById('card-class-range').classList.remove('d-none')
                document.getElementById('card-class-range').textContent = this.cards[e].class_range
            }
        },
        setGridHeight() {
            // set height of all cards = the largest card or 300 whichever is lower
            var tallest = 0
            for (var i=0; i < this.cards.length; i++) {
                var height = document.getElementById('card-'+i).offsetHeight
                if (height > tallest) { tallest = height}
            }
            if (tallest > 300) { tallest = 300}
            for (var j=0; j < this.cards.length; j++) {
                document.getElementById('card-'+j).style.height = tallest + "px"
            }
        }
    },
    mounted() {
        // this for when user navigates back from card view
        // find screen size
        if (window.innerWidth > 576) { 
            this.setGridHeight() 
        }
    },
    updated() {
        // this for first load
        // find screen size
        if (window.innerWidth > 576) { 
            this.setGridHeight() 
        }
    },
}
</script>

<style scoped>
#card-view {
    text-align:left;
}
ol {
  padding-left: 1em;
}
</style>